import createAxiosInstance from "../utils/apiUtils";

import { userBaseUrl } from "../constants/Constants";
// import { LOCAL } from "./Constants";

const instance = createAxiosInstance({
	baseURL: userBaseUrl + "/deal/",
});
// const localInstance = createAxiosInstance({
// 	baseURL: LOCAL,
// });
//Get a list of all the deals for a by a dealerID and locationID
export const getDealList = async (dealerID, locationID) => {
	return await instance.get(`list/${dealerID}/${locationID}`);
};

// Get a list of the deals by Status
export const getDealListByStatus = async (dealerID, locationID, status) => {
	return await instance.get(`list/${dealerID}/${locationID}/${status}`);
};

export const getDealListByDateRange = async (
	locationID,
	status,
	fromDate,
	toDate
) => {
	return await instance.get(
		`listByDateRange/${locationID}/${status}?fromDate=${fromDate}&toDate=${toDate}`
	);
};
export const getNumberOfDealsByDateRange = async (
	locationID,
	fromDate,
	toDate
) => {
	return await instance.get(
		`dealsByDateRange/${locationID}?fromDate=${fromDate}&toDate=${toDate}`
	);
};

export const getDealListBySearch = async (locationID, filter) => {
	return await instance.post(`ListBySearch/${locationID}`, { filter });
};

// Get a list of customers needed for deal header
export const getDealCustomerList = async (dealerID, locationID) => {
	return await instance.get(`header/customer/list/${dealerID}/${locationID}`);
};

// Get a list of vehicles needed for deal header
export const getDealInventoryList = async (dealerID, locationID) => {
	return await instance.get(`header/inventory/list/${dealerID}/${locationID}`);
};

// Saves a deal
export const saveDeal = async (body) => {
	console.log(body);

	return await instance.post(`save`, body);
};

// Delete a deal
export const deleteDeal = async (dealID, deleteType) => {
	return await instance.get(`delete/${dealID}/${deleteType}`);
};

// Retrieves information about a deal
export const getDealDetails = async (dealID) => {
	return await instance.get(`${dealID}`);
};

export const transferDealToSold = async (deal) => {
	return await instance.post(`transfer-deal-to-sold`, deal);
};

export const transferDealToSoldv2 = async (dealID) => {
	return await instance.get(`transfer-deal-to-sold/${dealID}`);
};

export const unwindDeal = async (deal) => {
	return await instance.post(`unwind-deal`, deal);
};

export const unwindDealv2 = async (dealID) => {
	return await instance.get(`unwind-deal/${dealID}`);
};

export const saveUACDealInfo = async (deal) => {
	return await instance.post(`uac/save`, deal);
};

// Retrieves information about a deal
export const getTaxRateByZip = async (zip) => {
	return await instance.get(`tax/zip/${zip}`);
};

// Retrieves information about a County
export const getByCountyRecNum = async (recnum) => {
	return await instance.get(`tax/${recnum}`);
};

// Retrieves tax rate by zip and includes custom taxes dealers created
export const getZipAndCustomTaxRate = async (
	dealerID,
	locationID,
	zips,
	countyRecNum
) => {
	console.log(dealerID, locationID, zips, countyRecNum);
	return await instance.get(
		`tax/zip/${dealerID}/${locationID}/${zips}?recNum=${countyRecNum}`
	);
};

// Retrieves information about a deal
export const getTaxRateByState = async (state) => {
	return await instance.get(`tax/state/${state}`);
};

// Retrieves tax rate by state and includes custom taxes dealers created
export const getStateAndCustomTaxRate = async (dealerID, locationID, state) => {
	return await instance.get(`tax/state/${dealerID}/${locationID}/${state}`);
};

// Saves a dealer's custom tax info
export const saveCustomTax = async (tax) => {
	return await instance.post(`tax/custom/save`, tax);
};

// Aftermarket
export const getAftermarket = async (ID) => {
	return await instance.get(`aftermarket/${ID}`);
};

export const saveAftermarket = async (body) => {
	return await instance.post(`aftermarket/save`, body);
};

export const deleteAftermarket = async (ID) => {
	return await instance.post(`aftermarket/delete/${ID}`, null);
};

// Seller Fees
export const getMiscSellerFees = async (ID) => {
	return await instance.get(`seller/${ID}`);
};

export const saveMiscSellerFees = async (body) => {
	return await instance.post(`seller/save`, body);
};

export const deleteMiscSellerFee = async (ID) => {
	return await instance.post(`seller/delete/${ID}`, null);
};

// Pickup/Deferred Payments
export const getPickupPayments = async (ID) => {
	return await instance.get(`pickup/${ID}`);
};

export const savePickupPayment = async (body) => {
	return await instance.post(`pickup/save`, body);
};

export const deletePickupPayment = async (ID) => {
	return await instance.post(`pickup/delete/${ID}`, null);
};

// Warranty
export const getWarranty = async (ID) => {
	return await instance.get(`warranty/${ID}`);
};

export const saveWarranty = async (body) => {
	return await instance.post(`warranty/save`, body);
};

export const deleteWarranty = async (ID) => {
	return await instance.post(`warranty/delete/${ID}`, null);
};

export const getDealsStatusList = async (locationID) => {
	return await instance.get(`dealsByStatus/${locationID}`);
};

export const getInventoryStatusList = async (locationID) => {
	return await instance.get(`inventoryByStatus/${locationID}`);
};
export const getInventoryStatusByDateRenge = async (
	locationID,
	fromDate,
	toDate
) => {
	return await instance.get(
		`inventoryByDateRange/${locationID}?fromDate=${fromDate}&toDate=${toDate}`
	);
};

export const getNumberOfCustomers = async (locationID) => {
	return await instance.get(`numberOfCustomer/${locationID}`);
};

export const getVehicleDeals = async (inventoryID) => {
	return await instance.get(`inventory/list/${inventoryID}`);
};

export const getDealTemplatesNew = async (dealerID, locationID) => {
	return await instance.get(`template/new/list/${dealerID}/${locationID}`);
};

export const getDealTemplate = async (id) => {
	return await instance.get(`template/${id}`);
};

export const getBuildDealTemplate = async (id) => {
	return await instance.get(`template/build/${id}`);
};

export const saveDealTemplate = async (template) => {
	return await instance.post(`template/save`, template);
};

export const deleteDealTemplate = async (template) => {
	return await instance.post(`template/delete`, template);
};

//get a list of one customer associate with deals
export const getCustomerDeals = async (originalID) => {
	return await instance.get(`customer/list/${originalID}`);
};

// Commission
export const getCommissions = async (ID) => {
	return await instance.get(`commissions/${ID}`);
};

export const saveCommission = async (body) => {
	return await instance.post(`commissions/save`, body);
};

export const deleteCommission = async (ID) => {
	return await instance.post(`commissions/delete/${ID}`, null);
};
//get  list of selerFee types
export const getSelerFeeTypes = async (code) => {
	return await instance.get(`seller/sellerFeeTypeList?code=${code}`);
};

//build new deal Object
export const buildNewDeal = async (locationID) => {
	return await instance.get(`new/${locationID}`);
};

export const deleteDownPayment = async (ID) => {
	return await instance.post(`payments/delete/${ID}`, null);
};
