//API Constants
export const userBaseUrl = process.env.REACT_APP_API_BASE_URL + "/api";
export const devBaseUrl = process.env.REACT_APP_API_BASE_URL + "/dev";
export const loginBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const LOCAL = "http://localhost:8080";
export const LOCAL8081 = "http://localhost:8081";
export const DC_AI_API_BASE_URL =
	"https://us-central1-dc-web-services.cloudfunctions.net/ai-inv-description";

export const PATHS = {
	//Base
	DEV: "/dev",

	//Paths
	DASHBOARD: "/dashboard",
	LOGIN: "/login",
	INVENTORY_LIST: "/inventory-list",
	INVENTORY_SEARCH_LIST: "/inventory-search-list",
	INVENTORY_ADD: "/inventory-add",
	INVENTORY_APPRAISAL: "/inventory-appraisal",
	INVENTORY_REPORTS: "/inventory-reports",
	INVENTORY_DETAIL: "/inventory-detail",
	CUSTOMER_LIST: "/customer-list",
	CUSTOMER_ADD: "/customer-add",
	CUSTOMER_DETAIL: "/customer-detail",
	CUSTOMER_SEARCH_LIST: "/customer-search-list",
	MESSAGING: "/message",
	MESSAGE_RECENT: "/messages/recent",
	LEAD_ADD: "/lead-add",
	LEAD_LIST: "/lead-list",
	CreditApp_LIST: "/CreditApp-list",
	LEAD_DETAIL: "/lead-detail",
	COMMUNICATIONS: "/communications",
	MESSAGE_TEMPLATES: "/message-templates",
	CREDIT_APPS: "/credit-apps",
	DEAL_LIST: "/deal-list",
	Acounts_LIST: "/acounts-LIST",
	DEAL_ADD: "/deal-add",
	DEAL_SETTINGS: "/deal-settings",
	DEAL_DETAIL: "/deal-detail",
	DEAL_SEARCH_LIST: "/deal-search-list",
	BUY_HERE_PAY_HERE: "/buy-here-pay-here",
	PAYMENT_ADD: "/payment-add",
	USER_PROFILE: "/user-profile",
	REPORTS: "/reports",
	BUYERS_GUIDE: "/buyers-guide",
	SETTINGS: "/settings",
	NEW_USER: "/new-user",
	USER_ACCOUNT: "/user-account",
	PAYMENT_TERMINAL: "/payment-terminal",
	INVENTORY_ADVERTISING: "/inventory-advertising",
	VENDORS: "/vendors",
	UPDATE_PASSWORD: "/update-password",
	FORGOTTEN_PASSWORD: "/forgotten-password",
	BackDoor_Login: "/backdoor_login",
	FormGen: "/formgen",
	QuickQuote: "/QuickQuote",
	MultiDealer: "/multi-dealer",

	ACCT_LIST: "/acct_list",
	ACCT_DETAIL: "/acct_detail",
	ACCT_ADD: "/acct_add",
};

/**
 * Takes in an enumerated object and returns an array used for selectors.
 * Enumerated objects are good for accessing values without having to rely on index
 * @param {*} enumObj
 * @returns
 */
export const getOptions = (enumObj) => {
	return Object.keys(enumObj).map((option) => enumObj[option]);
};

export const vendorTypesOBJ = {
	VENDOR: { value: 10, label: "Vendor" },
	WARRANTY: { value: 20, label: "Warranty" },
	//LienHolder: { value: 25, label: "Lien Holder" },
	LENDER: { value: 30, label: "Lender" },
	DEALERCLICK_LENDER: { value: 31, label: "DealerClick Lender" },
	SHIPPING_COMPANIES: { value: 40, label: "Shipping Companies" },
	RECON_SUPPLIER: { value: 50, label: "Recon Supplier" },
	AFTMKT_SUPPLIER: { value: 60, label: "Aftermarket Supplier" },
	FLOORING_VENDOR: { value: 70, label: "Flooring Vendor" },
	//TradeLender: { value: 80, label: "Trade Lender" },
	GAP_COMPANIES: { value: 90, label: "Gap/Insurance Companies" },
};

// export const PayOffMethod = {
// 	PAYOFF_RULE_OF_78: { value: 0, label: "RULE_OF_78" },
// 	PAYOFF_SIMPLE_INT: { value: 1, label: "SIMPLE_INT" },
// 	PAYOFF_SCHEDULE_INT: { value: 3, label: "SCHEDULE_INT" },
// };
export const PayOffMethod = [
	{ value: 0, label: "RULE_OF_78" },
	{ value: 1, label: "SIMPLE_INT" },
	{ value: 3, label: "SCHEDULE_INT" },
];

export const vendorTypes = {
	10: "Vendor",
	20: "Warranty",
	//	25: "Lien Holder",
	30: "Lender",
	40: "Shipping Companies",
	50: "Recon Supplier",
	60: "Aftermarket Supplier",
	70: "Flooring Vendor",
	//	80: "Trade Lender",
	90: "Gap/Insurance Companies",
};
export const INS_TYPE = [
	{ value: 0, label: "None" },
	{ value: 1, label: "SINGLE_LIFE" },
	{ value: 2, label: "JOINT_LIFE" },
];
export const INS_TYPE_AH = [
	{ value: 0, label: "None" },
	{ value: 3, label: "14 DAYS RETRO" },
	{ value: 4, label: "7  DAYS RETRO" },
	{ value: 5, label: "30 DAYS RETRO" },
];
export const taxRateEnum = {
	SPECIFIC_RATE: 1,
	NONTAXABLE: 0,
	DEFAULT_TAX_RATE: -1,
};
export const genderType = [
	{ value: "M", label: "Male" },
	{ value: "F", label: "Female" },
	{ value: "O", label: "Other" },
];
export const generationType = [
	{ value: "N/A", label: "N/A" },
	{ value: "Sr", label: "Sr" },
	{ value: "Jr", label: "Jr" },
	{ value: "2nd", label: "2nd" },
	{ value: "3rd", label: "3rd" },
	{ value: "4th", label: "4th" },
	{ value: "I", label: "I" },
	{ value: "II", label: "II" },
	{ value: "III", label: "III" },
	{ value: "IV", label: "IV" },
];
export const titleStatusOptions = [
	{ label: "Clean", value: "Clean" },
	{ label: "Salvage", value: "Salvage" },
	{ label: "Bonded", value: "Bonded" },
	{ label: "Lemon", value: "Lemon" },
	{ label: "Rebuilt", value: "Rebuilt" },
	{ label: "Dismantled", value: "Dismantled" },
	{ label: "Junk", value: "Junk" },
	{ label: "Flood/Water Damage", value: "Flood/Water Damage" },
];

export const stateOptions = [
	{ label: "AK", value: "AK" },
	{ label: "AL", value: "AL" },
	{ label: "AR", value: "AR" },
	{ label: "AZ", value: "AZ" },
	{ label: "CA", value: "CA" },
	{ label: "CO", value: "CO" },
	{ label: "CT", value: "CT" },
	{ label: "DE", value: "DE" },
	{ label: "FL", value: "FL" },
	{ label: "GA", value: "GA" },
	{ label: "HI", value: "HI" },
	{ label: "IA", value: "IA" },
	{ label: "ID", value: "ID" },
	{ label: "IL", value: "IL" },
	{ label: "IN", value: "IN" },
	{ label: "KS", value: "KS" },
	{ label: "KY", value: "KY" },
	{ label: "LA", value: "LA" },
	{ label: "MA", value: "MA" },
	{ label: "MD", value: "MD" },
	{ label: "ME", value: "ME" },
	{ label: "MI", value: "MI" },
	{ label: "MN", value: "MN" },
	{ label: "MO", value: "MO" },
	{ label: "MS", value: "MS" },
	{ label: "MT", value: "MT" },
	{ label: "NC", value: "NC" },
	{ label: "ND", value: "ND" },
	{ label: "NE", value: "NE" },
	{ label: "NH", value: "NH" },
	{ label: "NJ", value: "NJ" },
	{ label: "NM", value: "NM" },
	{ label: "NV", value: "NV" },
	{ label: "NY", value: "NY" },
	{ label: "OH", value: "OH" },
	{ label: "OK", value: "OK" },
	{ label: "OR", value: "OR" },
	{ label: "PA", value: "PA" },
	{ label: "RI", value: "RI" },
	{ label: "SC", value: "SC" },
	{ label: "SD", value: "SD" },
	{ label: "TN", value: "TN" },
	{ label: "TX", value: "TX" },
	{ label: "UT", value: "UT" },
	{ label: "VA", value: "VA" },
	{ label: "VT", value: "VT" },
	{ label: "WA", value: "WA" },
	{ label: "WI", value: "WI" },
	{ label: "WV", value: "WV" },
	{ label: "WY", value: "WY" },
];
export const StateCodeByStateName = [
	{ label: "Armed Forces America	", value: "AA" },
	{ label: "Armed Forces", value: "AE" },
	{ label: "Alaska", value: "AK" },
	{ label: "Alabama", value: "AL" },
	{ label: "Armed Forces Pacific", value: "AP" },
	{ label: "Arkansas", value: "AR" },
	{ label: "Arizona", value: "AZ" },
	{ label: "California", value: "CA" },
	{ label: "Colorado", value: "CO" },
	{ label: "Connecticut", value: "CT" },
	{ label: "Washington DC", value: "DC" },
	{ label: "Delaware", value: "DE" },
	{ label: "Florida", value: "FL" },
	{ label: "Georgia", value: "GA" },
	{ label: "Guam", value: "GU" },
	{ label: "Hawaii", value: "HI" },
	{ label: "Iowa", value: "IA" },
	{ label: "Idaho", value: "ID" },
	{ label: "Illinois", value: "IL" },
	{ label: "Indiana", value: "IN" },
	{ label: "Kansas", value: "KS" },
	{ label: "Kentucky", value: "KY" },
	{ label: "Louisiana", value: "LA" },
	{ label: "Massachusetts", value: "MA" },
	{ label: "Maryland", value: "MD" },
	{ label: "Maine", value: "ME" },
	{ label: "Michigan", value: "MI" },
	{ label: "Minnesota", value: "MN" },
	{ label: "Missouri", value: "MO" },
	{ label: "Mississippi", value: "MS" },
	{ label: "Montana", value: "MT" },
	{ label: "North Carolina", value: "NC" },
	{ label: "North Dakota", value: "ND" },
	{ label: "Nebraska", value: "NE" },
	{ label: "New Hampshire", value: "NH" },
	{ label: "New Jersey", value: "NJ" },
	{ label: "New Mexico", value: "NM" },
	{ label: "Nevada", value: "NV" },
	{ label: "New York", value: "NY" },
	{ label: "Ohio", value: "OH" },
	{ label: "Oklahoma", value: "OK" },
	{ label: "Oregon", value: "OR" },
	{ label: "Pennsylvania", value: "PA" },
	{ label: "Puerto Rico", value: "PR" },
	{ label: "Rhode Island", value: "RI" },
	{ label: "South Carolina", value: "SC" },
	{ label: "South Dakota", value: "SD" },
	{ label: "Tennessee", value: "TN" },
	{ label: "Texas", value: "TX" },
	{ label: "Utah", value: "UT" },
	{ label: "Virginia", value: "VA" },
	{ label: "Virgin Islands", value: "VI" },
	{ label: "Vermont", value: "VT" },
	{ label: "Washington", value: "WA" },
	{ label: "Wisconsin", value: "WI" },
	{ label: "West Virginia", value: "WV" },
	{ label: "Wyoming", value: "WY" },
];

export const vehicleTypeOptions = [
	{ label: "ATV", value: "ATV" },
	{ label: "Boat", value: "Boat" },
	{ label: "Car", value: "Car" },
	{ label: "e-bikes", value: "e-bikes" },
	{ label: "Golf cart", value: "Golf cart" },
	{ label: "Go-Kart", value: "Go-Kart" },
	{ label: "Marine", value: "Marine" },
	{ label: "Motor-Scooter", value: "Motor-Scooter" },
	{ label: "Motorcycle", value: "Motorcycle" },
	{ label: "RV", value: "RV" },
	{ label: "Snowmobile", value: "Snowmobile" },
	{ label: "SUV", value: "SUV" },
	{ label: "Tractor", value: "Tractor" },
	{ label: "Trailer", value: "Trailer" },
	{ label: "Truck", value: "Truck" },
	{ label: "Mini Truck", value: "Mini Truck" },
	{ label: "Semi-trucks", value: "Semi-trucks" },
	{ label: "Semi-Trailer Truck", value: "Semi-Trailer Truck" },
	{ label: "UTV", value: "UTV" },
	{ label: "Van", value: "Van" },
	{ label: "Other", value: "Other" },
];

export const vehicleCategories = [
	{ label: "None", value: 0 },
	{ label: "Chassis", value: 1 },
	{ label: "Dump", value: 2 },
	{ label: "Grain/Hopper", value: 3 },
	{ label: "Platform/Flatbed", value: 4 },
	{ label: "Tanker", value: 5 },
	{ label: "Utility", value: 6 },
	{ label: "RV", value: 7 },
	{ label: "Lowboy", value: 8 },
	{ label: "Paver", value: 9 },
	{ label: "Stepdeck (Drop Deck)", value: 10 },
	{ label: "Cargo", value: 11 },
	{ label: "Equipment", value: 12 },
	{ label: "Car Haulers", value: 13 },
	{ label: "Gooseneck", value: 14 },
	{ label: "Snow/ATV", value: 15 },
	{ label: "Tilt", value: 16 },
	{ label: "Refrigerated", value: 17 },
	{ label: "Tank", value: 18 },
	{ label: "Horse", value: 19 },
	{ label: "Livestock", value: 20 },
	{ label: "Concession", value: 21 },
	{ label: "Landscape", value: 22 },
	{ label: "Office", value: 23 },
];

export const vehicleSubCategories = [
	{ label: "None", value: 0 },
	{ label: "Boat", value: 1 },
	{ label: "Chipper", value: 2 },
	{ label: "Curtain Side", value: 3 },
	{ label: "Dolly", value: 4 },
	{ label: "Insulated", value: 5 },
	{ label: "Mixer", value: 6 },
	{ label: "Motorcycle", value: 7 },
	{ label: "Open", value: 8 },
	{ label: "Travel Trailer", value: 9 },
	{ label: "Toy Hauler", value: 10 },
	{ label: "Pop Up", value: 11 },
	{ label: "Park Trailer", value: 12 },
	{ label: "Truck Camper", value: 13 },
	{ label: "Vertical Telescoping", value: 14 },
	{ label: "Teardrop", value: 15 },
];

// export const newUseOptions = [
// 	{ label: "New", value: "New" },
// 	{ label: "Used", value: "Used" },
// 	{ label: "Demo", value: "Demo" },
// 	{ label: "Consign", value: "Consign" },
// 	{ label: "Trade-In", value: "Trade-In" },
// 	{ label: "BroKered", value: "BroKered" },
// 	{ label: "Wholesale", value: "Wholesale" },
// 	{ label: "Pre-Owned", value: "Pre-Owned" },
// 	{ label: "Certified Pre-Owned", value: "Certified Pre-Owned" },
// ];
export const conditionOptions = [
	{ label: "Excellent", value: "Excellent" },
	{ label: "Very Good", value: "Very Good" },
	{ label: "Good", value: "Good" },
	{ label: "Fair", value: "Fair" },
];

export const kbbConditionOptions = [
	{ label: "Fair", value: "Fair" },
	{ label: "Good", value: "Good" },
	{ label: "Very Good", value: "Very Good" },
	{ label: "Excellent", value: "Excellent" },
];

export const newOrUsed = [
	{ label: "New", value: "New" },
	{ label: "Used", value: "Used" },
];

export const SaleType = [
	{ label: "RETAIL", value: 0 },
	{ label: "WHOLESALE", value: 1 },
	{ label: "BUSINESS", value: 2 },
	{ label: "FLEET", value: 3 },
	{ label: "DEALER_TRADE", value: 4 },
	{ label: "PURCHASE", value: 5 },
	{ label: "LEASE", value: 6 },
	{ label: "RTO", value: 7 },
	{ label: "QUICK_DEAL", value: 8 },
];
// daysInPeriod is derived from Peymans code, it is constant
export const LoanPeriod = [
	{ label: "WEEKLY", term: 52, daysInPeriod: 7, value: 0 },
	{ label: "BIWEEKLY", term: 26, daysInPeriod: 14, value: 1 },
	{ label: "SEMIMONTHLY", term: 24, daysInPeriod: 15, value: 2 },
	{ label: "MONTHLY", term: 12, daysInPeriod: 30, value: 3 },
	{ label: "ANNUALLY", term: 1, daysInPeriod: 360, value: 4 },
	{ label: "COD", term: 1, daysInPeriod: 0, value: 5 },
	{ label: "BIANNUALLY", term: 2, daysInPeriod: 180, value: 6 },
];

export const DealType = [
	{ label: "PURCHASE", value: "Purchase" },
	{ label: "PENDING", value: "Pending" },
	{ label: "SOLD", value: "Sold" },
	{ label: "REPO", value: "Repo" },
	{ label: "DELETED", value: "Deleted" },
	{ label: "FUNDED", value: "Funded" },
];
export const TaxRegion = [
	{ label: "Customer Zip", value: 0 },
	{ label: "Dealership Zip", value: 1 },
	{ label: "Manual", value: 2 },
];
export const TaxType = [
	{ label: "Advanced", value: 0 },
	{ label: "Deferred", value: 1 },
];

export const vehicleCostType = [
	{ label: "Reconditioning", value: 0 },
	{ label: "Flooring", value: 2 },
	{ label: "Other", value: 9 },
];

// AutoInventory equivalents
export const vehicleStatusDesc = [
	{ label: "AVAILABLE", value: 0 },
	{ label: "SOLD", value: 1 },
	{ label: "PENDING", value: 2 },
	{ label: "HOLD", value: 3 },
	{ label: "DELETED", value: 4 },
	{ label: "APPRAISAL", value: 5 },
	{ label: "TRADE", value: 6 },
	{ label: "SOLD WITHOUT DEAL", value: 9 },
	{ label: "Non Stock", value: 99 },
];
export const vehicleStatusList = [
	{ label: "AVAILABLE", value: "Available" },
	{ label: "SOLD", value: "Sold" },
	{ label: "PENDING", value: "Pending" },
	{ label: "HOLD", value: "Hold" },
	{ label: "DELETED", value: "Deleted" },
	{ label: "APPRAISAL", value: "Appraisal" },
	{ label: "TRADE", value: "Trade" },
	{ label: "SOLD WITHOUT DEAL", value: "Sold Without Deal" },
	{ label: "NON STOCK", value: "Non Stock" },
];

export const vehicleStatus = {
	AVAILABLE: "Available",
	SOLD: "Sold",
	PENDING: "Pending",
	HOLD: "Hold",
	DELETED: "Deleted",
	APPRAISAL: "Appraisal",
	TRADE: "Trade",
	SOLDWITHOUTDEAL: "Sold Without Deal",
	NONSTOCK: "Non Stock",
};

export const personnelType = [
	{ label: "Salesperson", value: 0 },
	{ label: "Closer", value: 1 },
	{ label: "Manager", value: 2 },
	{ label: "F & I Manager", value: 3 },
	{ label: "Clerical", value: 4 },
	{ label: "Other", value: 5 },
	{ label: "Service Technician", value: 6 },
	{ label: "Owner", value: 9 },
];

export const defaultPictureURL =
	"https://www.dealernetwork.com/images/inventory/nopicture1.jpg";
export const FTP_IMAGE_URL = "https://www.dealernetwork.com/images/inventory";

export const profitSettingsEnum = {
	NOT_PROFIT: { value: 0, label: "Not Profit" },
	FRONT: { value: 1, label: "Frontend Profit" },
	BACK: { value: 2, label: "Backend Profit" },
};

export const commissionSettingsEnum = {
	NO_COMMISSION: { value: 0, label: "Not commissionable" },
	FRONT_COMMISSION: { value: 1, label: "Frontend(SP) commissionable" },
	BACK_COMMISSION: { value: 2, label: "Backend(FI) commissionable" },
	BOTH_COMMISION: { value: 3, label: "Both(SP & FI) commisionable" },
};

export const commissionSettingsOptions = [
	commissionSettingsEnum.NO_COMMISSION,
	commissionSettingsEnum.FRONT_COMMISSION,
	commissionSettingsEnum.BACK_COMMISSION,
	commissionSettingsEnum.BOTH_COMMISION,
];
export const commTypeList = {
	SALE: { value: 10, label: "SALE" },
	FI: { value: 30, label: "F&I" },
	TOTAL: { value: 40, label: "TOTAL" },
	NETPROFIT: { value: 50, label: "NET_PROFIT" },
};
export const commType = [
	{ value: 10, label: "SALE" },
	{ value: 30, label: "F&I" },
	{ value: 40, label: "TOTAL" },
	{ value: 50, label: "NET_PROFIT" },
];
// export const commType = {
// 	10: "SALESPERSON",
// 	30: "CLOSER",
// 	40: "MANAGER",
// 	50: "FI_MANAGER",
// };

export const DealStatus = {
	PURCHASE: "Purchase",
	PENDING: "Pending",
	SOLD: "Sold",
	REPO: "Repo",
	DELETED: "Deleted",
	FUNDED: "Funded",
	VOID: "Void",
};

export const CustomerStatus = {
	PENDING: 0,
	SOLD: 1,
	DELETED: 2,
};

export const HousingStatus = [
	{ label: "Rent", value: "rent" },
	{ label: "Own", value: "own" },
	{ label: "Other", value: "Other" },
];

export const IncomeType = [
	{ label: "Pay Stub", value: "paystub" },
	{ label: "Cash Bank Statements", value: "cash-paid-bank-stmts" },
	{ label: "Job Letter", value: "job-letter" },
	{ label: "Permanent Disability", value: "perm-disability" },
	{ label: "Retirement", value: "retirement" },
	{ label: "Social Security Income", value: "social-security-income" },
	{ label: "VA Income", value: "va-income" },
	{ label: "Self-employment Tax Returns", value: "self-empl-tax-rtns" },
	{ label: "Self-employment Bank Statements", value: "self-empl-bank-stmts" },
	{ label: "Temporary Job", value: "temporary-job" },
	{ label: "Other", value: "other" },
];

export const EmployerType = [
	{ label: "Current Employer/Employment", value: "current" },
	{ label: "Secondary Employer/Employment", value: "secondary" },
	{ label: "Previous Employer/Employment", value: "previous" },
];

export const CountryCodes = [
	{ label: "Argentina", value: "ARG" },
	{ label: "Australia", value: "AUS" },
	{ label: "Brazil", value: "BRA" },
	{ label: "Canada", value: "CAN" },
	{ label: "Switzerland", value: "CHE" },
	{ label: "Chile", value: "CHL" },
	{ label: "China", value: "CHN" },
	{ label: "Colombia", value: "COL" },
	{ label: "Germany", value: "DEU" },
	{ label: "Egypt", value: "EGY" },
	{ label: "France", value: "FRA" },
	{ label: "Great Britain", value: "GBR" },
	{ label: "Indonesia", value: "IDN" },
	{ label: "Israel", value: "ISR" },
	{ label: "India", value: "IND" },
	{ label: "Japan", value: "JPN" },
	{ label: "South Korea", value: "KOR" },
	{ label: "Mexico", value: "MEX" },
	{ label: "Malaysia", value: "MYS" },
	{ label: "Norway", value: "NOR" },
	{ label: "New Zealand", value: "NZL" },
	{ label: "Pakistan", value: "PAK" },
	{ label: "Panama", value: "PAN" },
	{ label: "Peru", value: "PER" },
	{ label: "Philippines", value: "PHL" },
	{ label: "Russia", value: "RUS" },
	{ label: "Saudi Arabia", value: "SAU" },
	{ label: "Sweden", value: "SWE" },
	{ label: "Singapore", value: "SGG" },
	{ label: "Thailand", value: "THA" },
	{ label: "Turkey", value: "TUR" },
	{ label: "Ukraine", value: "UKR" },
	{ label: "United States of America", value: "USA" },
	{ label: "Venezuela", value: "VEN" },
	{ label: "Vietnam", value: "VNM" },
	{ label: "South Africa", value: "ZAF" },
];

export const CurrencyCodes = [
	{ label: "Argentine Peso", value: "ARS" },
	{ label: "Australian Dollar", value: "AUD" },
	{ label: "Brazilian Real", value: "BRL" },
	{ label: "Canadian Dollar", value: "CAD" },
	{ label: "Swiss Franc", value: "CHF" },
	{ label: "Chilean Peso", value: "CLP" },
	{ label: "Chinese Yuan Renminbi", value: "CNY" },
	{ label: "Colombian Peso", value: "COP" },
	{ label: "Egypt Pound", value: "EGP" },
	{ label: "Euro", value: "EUR" },
	{ label: "British Pound Sterling", value: "GBP" },
	{ label: "Indonesian Rupiah", value: "IDR" },
	{ label: "Israeli New Shekel", value: "ILS" },
	{ label: "Indian Rupee", value: "INR" },
	{ label: "Japanese Yen", value: "JPY" },
	{ label: "South Korean Won", value: "KRW" },
	{ label: "Mexican Peso", value: "MXN" },
	{ label: "Malaysian Ringgit", value: "MYR" },
	{ label: "Norwegian Krone", value: "NOK" },
	{ label: "New Zealand Dollar", value: "NZD" },
	{ label: "Panamanian Balboa", value: "PAB" },
	{ label: "Peruvian Sol", value: "PEN" },
	{ label: "Philippines Peso", value: "PHP" },
	{ label: "Pakistani Rupee", value: "PKR" },
	{ label: "Russian Ruble", value: "RUB" },
	{ label: "Saudi Arabia Riyal", value: "SAR" },
	{ label: "Swedish Krona", value: "SEK" },
	{ label: "Singapore Dollar", value: "SGD" },
	{ label: "Thai Baht", value: "THB" },
	{ label: "Turkish Lira", value: "TRY" },
	{ label: "Ukrainian Hryvnia", value: "UAH" },
	{ label: "United States Dollar", value: "USD" },
	{ label: "Venezuelan Bolívar Soberano", value: "VES" },
	{ label: "Vietnamese Dong", value: "VND" },
	{ label: "South African Rand", value: "ZAR" },
];

export const AdSource = [
	{ label: "Credit Application", value: "credit application" },
	{ label: "Website", value: "website" },
	{ label: "Facebook", value: "facebook" },
	{ label: "Craigslist", value: "craigslist" },
	{ label: "Referral", value: "referral" },
];

export const militaryType = [
	{ label: "N/A", value: "n/a" },
	{ label: "Active Duty", value: "active duty" },
	{ label: "Reserve", value: "reserve" },
	{ label: "Veteran", value: "veteran" },
];

export const customerType = [
	{ label: "Personal", value: 0 },
	{ label: "Business", value: 1 },
	{ label: "WholeSale", value: 2 },
	{},
	{},
	{ label: "Purchase", value: 5 },
];

export const ownershipType = [
	{ label: "Own", value: "own" },
	{ label: "Rent", value: "rent" },
	{ label: "Other", value: "other" },
];

export const commissionType = [
	{ label: "Front", value: 0 },
	{ label: "Back", value: 1 },
	{ label: "Net Commissionable", value: 2 },
	{ label: "Net Profit", value: 3 },
];

export const fuelTypeList = [
	{ label: "Gasoline", value: "G" },
	{ label: "Diesel", value: "D" },
	{ label: "Diesel (B20 capable)", value: "B" },
	{ label: "Gas/Electric Hybrid", value: "Y" },
	{ label: "Diesel/Electric Hybrid", value: "DH" },
	{ label: "Plug-in Gas/Electric Hybrid", value: "PI" },
	{ label: "Electric with Gas Generator", value: "EG" },
	{ label: "Electric", value: "L" },
	{ label: "Hydrogen Fuel Cell", value: "H" },
	{ label: "Natural Gas", value: "N" },
	{ label: "Natural Gas/Propane", value: "NP" },
	{ label: "Propane", value: "P" },
	{ label: "Flex Fuel", value: "F" },
	{ label: "Gas/Oil Injected", value: "GI" },
	{ label: "Gas/Oil Premix", value: "GP" },
	{ label: "OTHER", value: "O" },
];

export const ATTACHMENT_CATEGORY = [
	{ label: "Credit Report", value: "Credit Report" },
	{ label: "ID Card", value: "ID Card" },
	{ label: "Income Documents", value: "Income Documents" },
	{ label: "Tax Documents", value: "Tax Documents" },
	{ label: "Utility Bill", value: "Utility Bill" },
	{ label: "Bank Statements", value: "Bank Statements" },
	{ label: "Title", value: "Title" },
	{ label: "Inspection/Appraisal", value: "Inspection/Appraisal" },
	{ label: "Insurance", value: "Insurance" },
	{ label: "Proof of Payment", value: "Payment" },
	{ label: "Other", value: "Other" },
];
export const OnlineLeadStatus = [
	{ label: "Active", value: 0 },
	{ label: "Sold", value: 1 },
	{ label: "Pending Sale", value: 2 },
	{ label: "Lost Lead", value: 3 },
	{ label: "Deleted/Trash", value: 4 },
	{ label: "Archive", value: 5 },
	{ label: "Appointment", value: 6 },
	{ label: "Seller", value: 7 },
	{ label: "Unknown", value: 8 },
	{ label: "Cold Lead", value: 9 },
];

export const OnlineCreditAppStatus = [
	{ label: "Active", value: 1 },
	{ label: "DeActive", value: 0 },
];

export const ActualMileageFlagOption = [
	{ label: "Actual", value: 0 },
	{ label: "Not Actual", value: 1 },
	{ label: "Exempt", value: 2 },
	{ label: "Excess", value: 3 },
];
export const MessageZone = {
	DEAL: "Deal",
	CUSTOMER: "Customer",
	LEAD: "Lead",
	CREDITAPP: "CreditApp",
	LENDER: "Lender",
	ACCOUNT: "Account",
};
// this should contain Dealer specific variables
export const MESSAGE_Dealer_VARIABLES = {
	// Dealer stuff
	"<location_dealerNum>": "Dealer Number",
	"<location_fax>": "Dealer Fax",
	"<dealer_companyName>": "Dealer Name",
	"<location_address>": "Dealer Street",
	"<location_city>": "Dealer City",
	"<location_state>": "Dealer State",
	"<location_zipCode>": "Dealer Zip",
	"<location_phone>": "Dealer Phone",
	"<dealer_fullAddress>": "Full Address",
	"<dealer_todaysDate>": "Today's Date",
};

// this should contain dcTableCustomer specific variables
export const MESSAGE_CUSTOMER_VARIABLES = {
	// Buyer/DcTableCustomer stuff
	"<buyer_firstName>": "First Name",
	"<buyer_lastName>": "Last Name",
	"<buyer_middleName>": "Middle Name",
	"<buyer_creditScore>": "TU Score",
	"<buyer_creditScoreEfx>": "EFX Score",
	"<buyer_creditScoreXpn>": "XPN Score",
};

export const MESSAGE_COBUYER_VARIABLES = {
	// CoBuyer stuff
	"<coBuyer_firstName>": "Co First Name",
	"<coBuyer_lastName>": "Co Last Name",
};

// this should contain Vehicle specific variables
export const MESSAGE_Vehicle_VARIABLES = {
	// Vehicle stuff
	"<vehicle_year>": "Year",
	"<vehicle_make>": "Make",
	"<vehicle_model>": "Model",
	"<vehicle_stockNo>": "Stock Number",
	"<vehicle_vin>": "VIN",
};

// this should contain Lead specific variables
export const MESSAGE_LEAD_VARIABLES = {
	"<lead_firstName>": "First Name",
	"<lead_lastName>": "Last Name",
	"<lead_contactMname>": "Middle Name",
	// "<lead_businessName>": "Business Name",
	"<lead_year>": "Target Year",
	"<lead_make>": "Target Make",
	"<lead_model>": "Target Model",
	"<lead_color>": "Target Color",
	"<lead_stockNumber>": "Stock Number",
	...MESSAGE_Vehicle_VARIABLES,
	...MESSAGE_Dealer_VARIABLES,
};

// this should contain CreditApp specific variables
export const MESSAGE_CreditApp_VARIABLES = {
	"<creditApp_firstName>": "First Name",
	"<creditApp_lastName>": "Last Name",
	"<creditApp_middleName>": "Middle Name",
	"<creditApp_companyName>": "Business Name",
	"<creditApp_year>": "Target Year",
	"<creditApp_vehicleMake>": "Target Make",
	"<creditApp_vehicleModel>": "Target Model",
	"<creditApp_vehicleStockNum>": "Stock Number",
	"<creditApp_vin>": "vin",
	...MESSAGE_Vehicle_VARIABLES,
	...MESSAGE_Dealer_VARIABLES,
};
export const MESSAGE_Deal_VARIABLES = {
	// Deal Stuff
	"<deal_dealNumber>": "Deal Number",
	"<deal_loanDate>": "Deal Date",
	"<deal_price>": "Price",
	"<deal_amtFinanced>": "Amount Financed",
	"<deal_tax>": "Tax",
	"<deal_term>": "Term",
	"<deal_loanPeriod>": "Period",
	"<deal_apr>": "APR",
	"<deal_down>": "Down Payment",
	"<deal_totalNetTrade>": "Net Trade",
	"<deal_payment>": "Payment",

	"<deal_status>": "Deal Status",
	"<deal_type>": "Deal Type",
	"<deal_salesperson>": "Sales person",
	"<deal_RequestDocs>": "Request Docs",
};
export const MESSAGE_ACCOUNT_VARIABLES = {
	"<account_accountNumber>": "accountNumber",
	"<account_subStatus>": "subStatus",
	"<account_totalLateFees>": "totalLateFees",
	"<account_financeCompany>": "financeCompany",
	"<account_balance>": "balance",
	"<account_Principle>": "Principle",
	"<account_principleDue>": "principleDue",
	"<account_interest>": "interest",
	"<account_interestDue>": "interestDue",
	"<account_principleRemaining>": "principleRemaining",
	"<account_unPaidInterest>": "unPaidInterest",
	"<account_lastAmtPaid>": "lastAmtPaid",
	"<account_totalPaid>": "totalPaid",
	"<account_interestPaid>": "interestPaid",
	"<account_adjPaid>": "adjPaid",
	"<account_taxPaid>": "taxPaid",
	"<account_pickupPaid>": "pickupPaid",
	"<account_pastDueAmt>": "pastDueAmt",
	"<account_payoff>": "payoff",
	"<account_nextDueDate>": "nextDueDate",
	...MESSAGE_Dealer_VARIABLES,
	...MESSAGE_CUSTOMER_VARIABLES,
};

export const MESSAGE_Lender_VARIABLES = {
	"<lender_name>": "name",
	"<lender_contact1>": "contact1",
	"<lender_street>": "street",
	"<lender_city>": "city",
	"<lender_state>": "state",
	"<lender_zip>": "zip",
	"<lender_phone1>": "phone1",
	"<lender_phone2>": "phone2",
	"<lender_email>": "email",
	"<lender_website>": "website",
};

export const MESSAGE_TEMPLATE_VARIABLES = {
	...MESSAGE_CUSTOMER_VARIABLES,
	...MESSAGE_COBUYER_VARIABLES,
	...MESSAGE_Deal_VARIABLES,
	...MESSAGE_Vehicle_VARIABLES,
	...MESSAGE_Dealer_VARIABLES,
	...MESSAGE_Lender_VARIABLES,
	...MESSAGE_ACCOUNT_VARIABLES,
};

export const sellerFeeTypeList = [
	{ label: "Other", value: null },
	{ label: "Business Partner Automation (BPA)", value: "BPA" },
	{ label: "Electronic Registration Title (ERT)", value: "ERT" },
	{ label: "Electric Vehicle (EV)", value: "EV" },
	{ label: "Theft Deterrent", value: "theft" },
	{ label: "Surface Protection", value: "surface" },
	{ label: "Vehicle Single Insurance (VSI)", value: "VSI" },
];

export const driverLicenseOption = [
	{ label: "In-State Driver's License", value: "In-State Driver's License" },
	{
		label: "Out-of-State Driver's License",
		value: "Out-of-State Driver's License",
	},
	{
		label: "Consulate Issued ID / Matricula",
		value: "Consulate Issued ID / Matricula",
	},
	{ label: "ID Card", value: "ID Card" },
	{
		label: "Out-of-County / Int'l Driver's License",
		value: "Out-of-County / Int'l Driver's License",
	},
	{ label: "Passport", value: "Passport" },
	{
		label: "Restricted / Suspended Driver's License",
		value: "Restricted / Suspended Driver's License",
	},
];

export const canadaStateOption = [
	{ label: "Ontario", value: "ON" },
	{ label: "Quebec", value: "QC" },
	{ label: "Nova Scotia", value: "NS" },
	{ label: "New Brunswick", value: "NB" },
	{ label: "Manitoba", value: "MB" },
	{ label: "British Columbia", value: "BC" },
	{ label: "Prince Edward Island", value: "PE" },
	{ label: "Saskatchewan", value: "SK" },
	{ label: "Alberta", value: "AB" },
	{ label: "Newfoundland and Labrador", value: "NL" },
];

export const isTaxableOption = [
	{ value: "true", label: "Yes" },
	{ value: "false", label: "No" },
];

export const rollOptions = [
	// here for informative reasons
	// {label: "No Roll", value: 0},
	{ label: "Price", value: 10 },
	{ label: "Down", value: 20 },
	{ label: "Interest (APR)", value: 40 },
	{ label: "Term", value: 90 },
];

export const companyFeaturesList = [
	{ value: "isWordpressActive", label: "Wordpress" },
	{ value: "useKall8", label: "Kall8" },
	{ value: "sendXMRadio", label: "SiriusXM" },
	{ value: "useTrueValue", label: "TrueValue" },
	{ value: "sendCarfax", label: "Carfax" },
	{ value: "sendAutoCheck", label: "Autocheck" },
	{ value: "useLenderArea", label: "Lender Area" },
	{ value: "useCRM", label: "CRM Area" },
	{ value: "sendWebsite", label: "Hosting" },
	{ value: "sendExternalWebsite", label: "External Inventory" },
	{ value: "sendXML", label: "XML Inventory" },
	{ value: "usedCreditAppArea", label: "Credit App Area" },
	{ value: "sendCBC", label: "CBC" },
	{ value: "useAndroid", label: "Android" },
	{ value: "usePop3", label: "Pop3" },
	{ value: "usePreviousOwner", label: "Previous Owner" },
	{ value: "kbbidws", label: "KBB IDWS" },
	{ value: "sendFacebook", label: "Facebook.com" },
	{ value: "useIphone", label: "iPhone" },
	{ value: "sendTitleTec", label: "TitleTec" },
	{ value: "sendBlockchain", label: "Blockchain" },
	{ value: "useUACC", label: "UACC" },
];

export const locationFeatureList = [
	{ value: "keyless", label: "Keyless" },
	{ value: "dc", label: "DMS" },
	{ value: "cr", label: "Credit Reports" },
	{ value: "qb", label: "QuickBook" },
	{ value: "ls", label: "Leasing" },
	{ value: "nls", label: "RTO" },
	{ value: "fn", label: "BHPH" },
	{ value: "ps", label: "Part & Service" },
	{ value: "psTimeClock", label: "Time Clock" },
	{ value: "psBarCode", label: "BarCode" },
];

export const paymentMethod = [
	{ value: "cash", label: "Cash" },
	{ value: "creditCard", label: "Credit Card" },
	{ value: "debitCard", label: "Debit Card" },
	{ value: "moneyOrder", label: "Money Order" },
	{ value: "cashierCheck", label: "CashiersCheck" },
	{ value: "zelle", label: "Zelle" },
	{ value: "moneyGram", label: "MoneyGram" },
	{ value: "paypal", label: "PayPal" },
	{ value: "transfer", label: "TRANSFER" },
	{ value: "echeck", label: "E-Check" },
	{ value: "check", label: "Check" },
	{ value: "other", label: "Other" },
	{ value: "creditIssued", label: "Credit Issued" },
	{ value: "skipped", label: "Skipped" },
	{ value: "ach", label: "A C H" },
];
export const purchaseType = [
	...paymentMethod,
	{ value: "flooring", label: "Flooring" },
	{ value: "draft", label: "Draft" },
	{ value: "consignment", label: "Consignment" },
	{ value: "certified Check", label: "Certified Check" },
];

export const driveType = [
	{ label: "Front-wheel drive (FWD)", value: "FWD" },
	{ label: "Rear-wheel drive (RWD)", value: "RWD" },
	{ label: "All-wheel drive (AWD)", value: "AWD" },
	{ label: "Four-wheel drive (4WD)", value: "4WD" },
];

export const smogCheck = [
	{ label: "Not Checked", value: 0 },
	{ label: "Passed", value: 1 },
	{ label: "Failed", value: 2 },
];

export const credit700Products = {
	saveOnly: "SAVEONLY",
	credit: "CREDIT",
	prescreen: "PRESCREEN",
};

export const credit700Lenders = {
	routeOne: "R1",
	dealerTrack: "DT",
	cudl: "CUDL",
	appOne: "APPONE",
};

export const credit700Bureaus = {
	experian: "XPN",
	transUnion: "TU",
	equifax: "EFX",
};
export const titleBrands = [
	{ value: "Clean", label: "Clean" },
	{ value: "Collision", label: "Collision" },
	{ value: "Damaged", label: "Damaged" },
	{ value: "Water-Damage", label: "Water Damage" },
	{ value: "Storm-Damage", label: "Storm Damage" },
	{ value: "Dismantled", label: "Dismantled" },
	{ value: "Grey-Market", label: "Grey Market" }, //(Non-USA)
	{ value: "Junk", label: "Junk" },
	{ value: "Lemon", label: "Lemon law buyback" }, //(Warranty Return)
	{ value: "Police", label: "Original police" },
	{ value: "Taxi", label: "Original Taxi" },
	{ value: "Park Trailer", label: "Park Trailer" },
	{ value: "Rebuilt", label: "Rebuilt" },
	{ value: "Reconditioned", label: "Reconditioned" },
	{ value: "Remanufactured", label: "Remanufactured" },
	{ value: "Replica", label: "Replica" },
	{ value: "Revived Junk", label: "Revived Junk" },
	{ value: "Revived Salvage", label: "Revived Salvage" },
	{ value: "Recycled", label: "Recycled" },
	{ value: "Salvage", label: "Salvage" },
	{ value: "Scrap Vehicle", label: "Scrap Vehicle" },
	{ value: "Totaled", label: "Totaled" },
	{ value: "Warranty Returned", label: "Warranty Returned" },
	{ value: "Vandalism", label: "Vandalism" },
	{ value: "TMU", label: "TMU" }, //True Mileage Unknown
	{ value: "Municipal", label: "Municipal Vehicle" },
	{ value: "Tripped", label: "Tripped" },
	{ value: "Flood", label: "Flood" },
];

/**
 * PepperPay option lists
 */
export const MailingType = [
	{ value: "DBA Address", label: "DBA Address" },
	{ value: "Legal Address", label: "Legal Address" },
];
export const jobTitles = [
	{ value: "CEO", label: "CEO" },
	{ value: "CFO", label: "CFO" },
	{ value: "Chairman", label: "Chairman" },
	{ value: "Co-owner", label: "Co-owner" },
	{ value: "Comptroller", label: "Comptroller" },
	{ value: "Controller", label: "Controller" },
	{ value: "Director", label: "Director" },
	{ value: "General Manager", label: "General Manager" },
	{ value: "Office Manager", label: "Office Manager" },
	{ value: "Owner", label: "Owner" },
	{ value: "Partner", label: "Partner" },
	{ value: "President", label: "President" },
	{ value: "Treasurer", label: "Treasurer" },
	{ value: "Vice President", label: "Vice President" },
];
export const OwnershipTypePeperpay = [
	{ value: "Individual/Sole Proprietor", label: "Individual/Sole Proprietor" },
	{ value: "Partnership", label: "Partnership" },
	{ value: "LLC", label: "LLC" },
	{ value: "Association/Estate/Trust", label: "Association/Estate/Trust" },
	{
		value: "Government(Federal, State, Local)",
		label: "Government(Federal, State, Local)",
	},
	{ value: "Corporation - Chapter S, C", label: "Corporation - Chapter S, C" },
	{ value: "Tax Exempt Organization", label: "Tax Exempt Organization" },
	{
		value: "Medical or Legal Corporation",
		label: "Medical or Legal Corporation",
	},
	{ value: "International Organization", label: "International Organization" },
];
export const AccountOwnership = [
	{ value: "business", label: "business" },
	{ value: "personal", label: "personal" },
];
export const BankruptcyFiled = [
	{ value: "never filed", label: "never filed" },
	{ value: "business bankruptcy", label: "business bankruptcy" },
	{ value: "personal bankruptcy", label: "personal bankruptcy" },
];
export const BankAccountType = [
	{ value: "Checking", label: "Checking" },
	{ value: "Savings", label: "Savings" },
];
export const typeOfGoodsServices = [
	{ value: "Retail", label: "Retail" },
	{ value: "Retail W Tip", label: "Retail W Tip" },
	{ value: "Mail Phone", label: "Mail Phone" },
	{ value: "Internet", label: "Internet" },
	{ value: "Restaurant", label: "Restaurant" },
	{ value: "Fast Food", label: "Fast Food" },
	{ value: "Lodging", label: "Lodging" },
	{ value: "QRS", label: "QRS" },
	{ value: "Convenience", label: "Convenience" },
	{ value: "Public Sector", label: "Public Sector" },
	{ value: "Petroleum", label: "Petroleum" },
	{ value: "Utility", label: "Utility" },
	{ value: "Health Care", label: "Health Care" },
	{ value: "Other", label: "Other" },
];
export const LocationAreaZoned = [
	{ value: "Commercial", label: "Commercial" },
	{ value: "Industrial", label: "Industrial" },
	{ value: "Residential", label: "Residential" },
];
export const locationBuildingType = [
	{ value: "Shopping center", label: "Shopping center" },
	{ value: "Office Building", label: "Office Building" },
	{ value: "Industrial Building", label: "Industrial Building" },
	{ value: "Residence", label: "Residence" },
	{ value: "Other", label: "Other" },
];
export const LocationMerchant = [
	{ value: "Rents", label: "Rents" },
	{ value: "Owns", label: "Owns" },
];
export const LocationSquareFootage = [
	{ value: "0-500", label: "0-500" },
	{ value: "501-2500", label: "501-2500" },
	{ value: "2501-5000", label: "2501-5000" },
	{ value: "5001-10000", label: "5001-10000" },
	{ value: "10000+", label: "10000+" },
];
export const BillingFrequency = [
	{ label: "Daily", value: "Daily" },
	{ label: "Weekly", value: "Weekly" },
	// { label: "Bi-Weekly", value: "Bi-Weekly" },
	{ label: "Monthly", value: "Monthly" },
	// { label: "Quarterly", value: "Quarterly" },
	// { label: "Annual", value: "Annual" },
	// { label: "Hourly", value: "Hourly" },
	// { label: "Custom", value: "Custom" },
];
export const PmtType = {
	INVOICE: "Invoice",
	LOAN: "Loan",
	LEASE: "Lease",
	RENT: "Rent",
	DOWN: "Down",
	PICKUP: "Pickup",
	DEFERRED: "Deferred",
	DEPOSIT: "Deposit",
};
export const PmtMode = {
	REGULAR: 0,
	PRINCIPAL_ONLY: 1,
	INTEREST_ONLY: 2,
	ESCROW: 3,
	LEASE_RENT: 4,
	ADJUSTMENT: 5,
	LATE_FEE: 6,
	DEPOSIT: 7,
	PICKUP: 8,
	DOWN: 9,
	DEFERRED: 10,
	MILEAGE: 11,
	INSURANCE: 12,
};
export const PaymentType = [
	{ label: "Invoice", value: "Invoice" },
	{ label: "Loan", value: "Loan" },
	{ label: "Lease", value: "Lease" },
	{ label: "Rent", value: "Rent" },
	{ label: "Down", value: "Down" },
	{ label: "Pickup", value: "Pickup" },
	{ label: "Deferred", value: "Deferred" },
	{ label: "Deposit", value: "Deposit" },
];
export const PaymentMode = [
	{ value: 0, label: "Regular" },
	{ value: 1, label: "Principal Only" },
	{ value: 2, label: "Interest Only" },
	{ value: 3, label: "Escrow" },
	{ value: 4, label: "Lease / Rent" },
	{ value: 5, label: "Adjustment" },
	{ value: 6, label: "Late Fee" },
	{ value: 7, label: "Deposit" },
	{ value: 8, label: "PickUp" },
	{ value: 9, label: "Down" },
	{ value: 10, label: "Deferred" },
	{ value: 11, label: "Mileage" },
	{ value: 12, label: "Insurance" },
];
export const PaymentTypeToModeMapping = {
	Invoice: [0], // Regular
	Loan: [0, 1, 2, 5, 6, 11, 12], //Regular, Principal Only, Interest Only, Adjustment, LATE FEE, Mileage, Insurance
	Lease: [4, 5, 6, 11, 12], // Lease / Rent, Adjustment, LATE FEE, Mileage, Insurance
	Rent: [4, 5, 6, 11, 12], // Lease / Rent, Adjustment, LATE FEE, Mileage, Insurance
	Down: [9], // Down
	Pickup: [8], // PickUp
	Deferred: [10], // Deferred
	Deposit: [7], // Deposit
};
export const PaymentStatus = [
	{ value: "completed", label: "Completed" },
	{ value: "pending", label: "Pending" },
	{ value: "failed", label: "Failed" },
	{ value: "inProgress", label: "In Progress" },
	{ value: "canceled", label: "Canceled" },
	{ value: "refundInitiated", label: "Refund Initiated" },
	{ value: "refunded", label: "Refunded" },
	{ value: "partiallyPaid", label: "Partially Paid" },
	{ value: "overPaid", label: "Overpaid" },
	{ value: "void", label: "Void" },
	{ value: "authorized", label: "Authorized" },
	{ value: "chargeback", label: "Chargeback" },
	{ value: "dispute", label: "Dispute" },
	{ value: "settled", label: "Settled" },
];

// Account status  Lable
//* this list shoud be exact as table records
export const AccountStatus = [
	{ label: "AVAILABLE", value: 0 },
	{ value: 5, main: 1, label: "TRANSFERRED" }, //Account transferred to another office
	{ value: 10, main: 1, label: "RENEWED" },
	{ value: 11, main: 0, label: "CURRENT" },
	{ value: 13, main: 1, label: "PAID_OFF" }, //	Paid or closed account/zero balance
	{ value: 20, main: 1, label: "VOID" }, //Void Account
	{ value: 61, main: 1, label: "PAID_SURRENDER" }, //	paid in full was a voluntary surrender
	{ value: 62, main: 1, label: "PAID_COLLECTION" }, //	paid in full was a collection account
	{ value: 63, main: 1, label: "PAID_REPOSSESSION" }, //	paid in full, was a repossession
	{ value: 64, main: 1, label: "PAID_CHARGEOFF" }, //	Paid in full, was a charge-off
	{ value: 65, main: 1, label: "PAID_FORECLOSURE" }, //paid in full, A foreclosure was started
	{ value: 67, main: 1, label: "BANKRUPTCY" }, //	Bankruptcy
	{ value: 68, main: 1, label: "PAID_FOR" }, // _LESS	Paid forless
	{ value: 69, main: 1, label: "BANKRUPTCY13" }, //	STATUS_BANKRUPTCY13
	{ value: 71, main: 0, label: "LATE_30" }, //Account  30-59 days past the due date
	{ value: 78, main: 0, label: "LATE_60" }, //Account  60-89 days past the due date
	{ value: 80, main: 0, label: "LATE_90" }, //Account  90-119 days past the due date
	{ value: 82, main: 0, label: "LATE_120" }, //Account 120-149 days past the due date
	{ value: 83, main: 0, label: "LATE_150" }, //Account 150-179 days past the due date
	{ value: 84, main: 0, label: "LATE_180" }, //Account 180 or more days past the due date
	{ value: 85, main: 0, label: "LOST_FOUND" }, //	LOST NOW FOUND
	{ value: 88, main: 1, label: "CLAIM_FILED" }, //	Claim filed with government
	{ value: 89, main: 1, label: "DEED_RECEIVED" }, //	Deed received in lieu of foreclosure
	{ value: 91, main: 1, label: "EARLY_DEFAULT" },
	{ value: 92, main: 1, label: "INSURANCE_CLAIM" }, //INSURANCE_CLAIM
	{ value: 93, main: 1, label: "COLLECTIONS" }, //	Account assigned to collections
	{ value: 94, main: 1, label: "FORECLOSURE" }, //Foreclosure Completed
	{ value: 95, main: 1, label: "SURRENDER" }, //	Voluntary surrender
	{ value: 96, main: 1, label: "REPOSSESSION" }, //	Merchandise was repossessed
	{ value: 97, main: 1, label: "UNPAID_LOSS" }, //	Unpaid balance reported as a loss(charge-off)
	{ value: 98, main: 1, label: "CONSUMER_LOST" }, //	CONSUMER LOST
	{ value: 99, main: 1, label: "DELETED" }, // Delete entire account
];
export const DateRange = [
	{ value: "Today", lable: "Today" },
	{ value: "This Week", lable: "This Week" },
	{ value: "This Month", lable: "This Month" },
	{ value: "This Year", lable: "This Year" },
	{ value: "Last 180 Days", lable: "Last 180 Days" },
	{ value: "Last Week", lable: "Last Week" },
	{ value: "Last Month", lable: "Last Month" },
	{ value: "Last Year", lable: "Last Year" },
	{ value: "Last Two Years", lable: "Last Two Years" },
	{ value: "Last Four Years", lable: "Last Four Years" },
	{ value: "All", lable: "All" },
];
export const employmentStatus = [
	{ label: "Employed", value: "Employed" },
	{ label: "Unemployed", value: "Unemployed" },
	{ label: "Self-Employed", value: "Self-Employed" },
	{ label: "Student", value: "Student" },
	{ label: "Retired", value: "Retired" },
	{ label: "Active Military", value: "Active Military" },
	{ label: "Retired Military", value: "Retired Military" },
	{ label: "Other", value: "Other" },
];
export const dateRange = [
	{ label: "Today", value: "Today" },
	{ label: "This Week", value: "This Week" },
	{ label: "This Month", value: "This Month" },
	{ label: "This Year", value: "This Year" },
	{ label: "Last 180 Days", value: "Last 180 Days" },
	{ label: "Last Week", value: "Last Week" },
	{ label: "Last Month", value: "Last Month" },
	{ label: "Last Year", value: "Last Year" },
	{ label: "Last Two Years", value: "Last Two Years" },
	{ label: "Last 4 Years", value: "Last 4 Years" },
];
export const tireTypes = [
	{ label: "ALL_SEASON", value: "All Season" },
	{ label: "SUMMER", value: "Summer" },
	{ label: "WINTER", value: "Winter" },
	{ label: "ALL_TERRAIN", value: "All Terrain" },
	{ label: "MUD", value: "Mud" },
	{ label: "PERFORMANCE", value: "Performance" },
	{ label: "TOURING", value: "Touring" },
	{ label: "TRACK_COMPETITION", value: "Track and Competition" },
	{ label: "RUN_FLAT", value: "Run Flat" },
	{ label: "ECO", value: "Eco" },
	{ label: "TRUCK", value: "Truck" },
	{ label: "RECAP", value: "Recap" },
];
export const vehicleTypes = [
	"RV",
	"TRUCK",
	"MARINE",
	"MPV",
	"SEMI-TRAILER TRUCK",
	"SEMI-TRUCKS",
	"OTHER",
];
